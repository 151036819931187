/* width */
.kiwi-nicklist-users::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
/* Track */
.kiwi-nicklist-users::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
/* Handle */
.kiwi-nicklist-users::-webkit-scrollbar-thumb {
  border-radius: 3px;
}
/* Handle on hover */
.kiwi-nicklist-users::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Adjust the sidebars width when this nicklist is in view */
.kiwi-container .kiwi-sidebar.kiwi-sidebar-section-nicklist {
  width: 200px;
  overflow: hidden;
}
.kiwi-nicklist {
  overflow: hidden;
  box-sizing: border-box;
  min-height: 100px;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.kiwi-nicklist-usercount {
  background: var(--cabrito-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  box-sizing: border-box;
  height: 43px;
  line-height: 40px;
  width: 100%;
}
.kiwi-nicklist-usercount .fa-search {
  opacity: 0.3;
  cursor: pointer;
  font-size: 1.2em;
  line-height: 40px;
  align-self: flex-start;
  margin-right: 15px;
}
.kiwi-nicklist-usercount .fa-search:hover,
.kiwi-nicklist--filtering .kiwi-nicklist-usercount .fa-search {
  opacity: 1;
}
.kiwi-nicklist-usercount input {
  width: 78%;
  height: 70%;
  border: none;
  margin-left: 5%;
  font-weight: normal;
  background: #fff;
  border-radius: 30px;
  outline: 0;
  padding: 0 15px 0 10px;
  opacity: 0;
  box-sizing: border-box;
}
.kiwi-nicklist--filtering .kiwi-nicklist-usercount input {
  opacity: 1;
}
.kiwi-nicklist-users {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  max-height: 100%;
  flex: 1 auto;
  line-height: 1.2em;
  scroll-behavior: smooth;
}
.cabrito-users {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: var(--cabrito-secondary);
  color: var(--cabrito-primary);
  box-sizing: border-box;
  line-height: 50px;
}
.cabrito-users div {
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-left: 5%;
}
.cabrito-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7%;
  background: var(--cabrito-green);
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .kiwi-container .kiwi-sidebar.kiwi-sidebar-section-nicklist {
    width: 100%;
    max-width: 210px;
  }
}
