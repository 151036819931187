



























































































.kiwi-statebrowser-usermenu {
    width: 100%;
    padding-bottom: 0;
    padding-top: 34px;
}

.kiwi-statebrowser-usermenu-network {
    padding: 0 0 10px 0;
    cursor: default;
}

.kiwi-statebrowser-usermenu-avatar {
    width: 60px;
    height: 60px;
    cursor: pointer;
    font-size: 1.8em;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    transition: background 0.2s;
    position: relative;
}

.kiwi-statebrowser-usermenu-avatar .kiwi-avatar-inner {
    border-width: 3px;
}

.kiwi-statebrowser-usermenu .kiwi-awaystatusindicator {
    position: absolute;
    top: 3px;
    right: -4px;
    width: 12px;
    height: 12px;
    border: 1px solid #000;
}

.kiwi-statebrowser-usermenu-body {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 0.8em;
    margin-bottom: 10px;
}

.kiwi-statebrowser-usermenu-body p {
    margin-bottom: 0;
}
